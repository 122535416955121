<script>
  import { Link } from "svelte-routing";
  import { onMount } from "svelte";
  import Carousel from "svelte-carousel";
  let carousel; // for calling methods of the carousel instance
  const handleNextClick = () => {
    carousel.goToNext();
  };
  let banner_img,
    lewis_img,
    mtvernon_img,
    maple_img,
    hanover_img,
    harwood_img,
    hovey_img,
    breed_img,
    sections;
  var icon = document.getElementById("icon");

  document.addEventListener("scroll", () => {
    sections.forEach((section) => {
      if (section.getBoundingClientRect().top <= document.body.scrollTop) {
        document.body.style.background = section.dataset.color;
      }
    });
  });

  const setImage = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth > 1200) {
      banner_img.src = "/images/people.webp";
    } else if (innerWidth <= 1200 && innerWidth >= 675) {
      banner_img.src = "/images/people_tablet.webp";
      lewis_img.src = "/images/3 Lewis St, Lynn-tablet.webp";
      mtvernon_img.src = "/images/12 Mt. Vernon St, Salem-tablet.webp";
      maple_img.src = "/images/18 Maplewood St, Malden-tablet.webp";
      hanover_img.src = "images/19 Hanover St, Lynn-tablet.webp";
      harwood_img.src = "/images/20 Harwood St, Lynn-tablet.webp";
      hovey_img.src = "/images/16 Hovey Terrace, Lynn-tablet.webp";
      breed_img.src = "/images/61 Breed St, Lynn-tabet.webp";
    } else if (innerWidth < 675) {
      // banner_img.src = "/images/people_phone.webp";
      lewis_img.src = "/images/3 Lewis St, Lynn-mobile.webp";
      mtvernon_img.src = "/images/12 Mt. Vernon St, Salem-responsive.webp";
      maple_img.src = "/images/18 Maplewood St, Malden-r.webp";
      hanover_img.src = "images/19 Hanover St, Lynn-r.webp";
      harwood_img.src = "/images/20 Harwood St, Lynn-r.webp";
      hovey_img.src = "/images/16 Hovey Terrace, Lynn-r.webp";
      breed_img.src = "/images/61 Breed St, Lynn-mobile.webp";
    }
  };

  window.onresize = () => {
    setImage();
  };

  onMount(() => {
    sections = document.querySelectorAll("section");
    setImage();
  });
  const downloadPdf = () => {
    window.open(location.href + "HouseRules.pdf", "_blank");
  };

  const onClickImg = (imgSrc) => {
    const modal = document.getElementById("myModal");
    const modalLewis = document.getElementById("modalLewis");
    const modalBreed = document.getElementById("modalBreed");
    const modalHanover = document.getElementById("modalHanover");
    const modalImg = document.getElementById("myImg");
    modal.style.display = "grid";
    modalImg.src = imgSrc;
    if (imgSrc === "/images/3 Lewis St, Lynn.webp") {
      modal.style.display = "none";
      modalLewis.style.display = "grid";
    } else if (imgSrc === "/images/61 Breed St, Lynn.webp") {
      modal.style.display = "none";
      modalBreed.style.display = "grid";
    } else if (imgSrc === "/images/19 Hanover St, Lynn.webp") {
      modal.style.display = "none";
      modalHanover.style.display = "grid";
    } else {
      modalLewis.style.display = "none";
      modalBreed.style.display = "none";
      modalHanover.style.display = "none";
      // Hide the modal for other images
    }

    setTimeout(() => {
      const buttons = document.querySelectorAll(".sc-carousel-button");
      buttons.forEach((button) => {
        button.style.backgroundColor = "white";
      });
    }, "100");
  };

  const onCloseModal = () => {
    const modal = document.getElementById("myModal");
    modal.style.display = "none";
  };
  const onCloseCarousel = () => {
    const modalLewis = document.getElementById("modalLewis");
    modalLewis.style.display = "none";

    const modalBreed = document.getElementById("modalBreed");
    modalBreed.style.display = "none";

    const modalHanover = document.getElementById("modalHanover");
    modalHanover.style.display = "none";

    //   icon.onclick = function() {
    //   if (this.className === "Icon") {
    //   this.className = "Icon open";
    //    } else {
    //    this.className = "Icon";
    //   }
    //  }

    // icon.className = "Icon";
  };
</script>

<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>HarwoodHouses</title>
  </head>
  <body>
    <section class="banner" data-color="white">
      <div class="container">
        <img
          class="logo-img"
          src="/images/HarwoodHousesLogo.webp"
          alt="Sprouting plant leaf next to company name"
        />
        <h5>
          Begin your journey in recovery at the North Shore's best sober living
          residences!
        </h5>
        <Link to="pdfEditor" id="pdfButton" class="btn">APPLY NOW!</Link>
        <div class="img">
          <img
            bind:this={banner_img}
            class="img-item"
            src="/images/people.webp"
            alt="two people sitting at a table drinking tea and a laptop is in front of them"
          />
        </div>
      </div>
    </section>
    <section data-color="white">
      <div class="container">
        <div class="long-text">
          <h2>
            The HarwoodHouses offer those in early recovery an exceptional
            opportunity to begin rebuilding their life in modern, clean, well
            equipped sober homes in Lynn, Malden and Salem.
          </h2>
          <span class="long-text-span">
            All houses have an on-site washer and dryer, free wifi, and all
            rooms are fully furnished. Our success rate of residents who have
            remained clean and sober and have moved on to the next phase of
            their life is over 72%! This industry leading statistic shows that
            the HarwoodHouses are your best bet when it comes to sober living
            solutions.</span
          >
          <br />
          <br />
          <span class="long-text-span">
            We offer both short and long term residency in a supportive, family
            oriented sober atmosphere. All houses are close to public
            transportation and have off street parking available. With a drama
            free, mature resident base and an on site manager who provides
            support and recovery options for all the residents, the Harwood
            Houses are your best bet if you're looking to get your life back
            together!</span
          >
        </div>
      </div>
    </section>
    <section class="three-column-cards" data-color="white">
      <div class="container">
        <hr />
        <div class="three-column-grid">
          <div class="item">
            <h2>Clean Living</h2>
            <p>Zero Tolerance for Drugs and Alcohol</p>
            <p>
              Twice weekly mandatory drug testing to maintain a 100% sober environment
            </p>
            <div class="cta-text">
              <a
                href="javascript:"
                on:click={downloadPdf}
                style="cursor: pointer;">SEE RULES</a
              >
            </div>
          </div>
          <div class="item">
            <div class="text-wrapper">
              <h2>Personal Responsibility</h2>
              <p>AA/NA meetings required</p>
              <p>Household participation through chores and meetings</p>
            </div>
          </div>
          <div class="item">
            <h2>New Environment</h2>
            <p>
              All homes are updated and clean, worthy of your new, clean
              life-style
            </p>
            <p>
              Onsite, 24/7 management by people who have been through similar
              issues and are committed to your new life
            </p>
          </div>
        </div>
        <hr id="properties" />
      </div>
    </section>
    <section class="properties" data-color="white">
      <div class="container">
        <div class="properties-grid">
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={lewis_img}
              on:click={() => onClickImg("/images/3 Lewis St, Lynn.webp")}
              class="card-img expand"
              src="/images/3 Lewis St, Lynn.webp"
              alt="outside of the house"
            />
            <span class="inner-text">Lewis St, Lynn, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={mtvernon_img}
              on:click={() =>
                onClickImg("/images/12 Mt. Vernon St, Salem.webp")}
              class="card-img expand"
              src="/images/12 Mt. Vernon St, Salem.webp"
              alt="outside of the house"
            />
            <span class="inner-text">Mt. Vernon St, Salem, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={hovey_img}
              on:click={() => onClickImg("/images/16 Hovey Terrace, Lynn.webp")}
              class="card-img expand"
              src="/images/16 Hovey Terrace, Lynn.webp"
              alt="outside of the house"
            />
            <span class="inner-text">Hovey Terrace, Lynn, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={maple_img}
              on:click={() =>
                onClickImg("/images/18 Maplewood St, Malden.webp")}
              class="card-img expand"
              src="/images/18 Maplewood St, Malden.webp"
              alt="outside of the house"
            />
            <span class="inner-text">Maplewood St, Malden, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={hanover_img}
              on:click={() => onClickImg("/images/19 Hanover St, Lynn.webp")}
              class="card-img expand"
              src="/images/19 Hanover St, Lynn.webp"
              alt="outside of the house"
            />
            <span class="inner-text expand">Hanover St, Lynn, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={harwood_img}
              on:click={() => onClickImg("/images/20 Harwood St, Lynn.webp")}
              class="card-img expand"
              src="/images/20 Harwood St, Lynn.webp"
              alt="outside of the house"
            />
            <span class="inner-text expand">Harwood St, Lynn, MA</span>
          </div>
          <div class="item">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
              bind:this={breed_img}
              on:click={() => onClickImg("/images/61 Breed St, Lynn.webp")}
              class="card-img expand"
              src="/images/61 Breed St, Lynn.webp"
              alt="outside of the house"
            />
            <span class="inner-text expand">Breed St, Lynn, MA</span>
          </div>
        </div>
        <hr />
      </div>
    </section>

    <section data-color="white">
      <div class="cta-banner container">
        <h2 class="animated-line-heading animate-complete h2">
          Let’s move things forward.
        </h2>
        <Link to="pdfEditor" id="pdfButton" class="cta-button">APPLY NOW!</Link>
      </div>
    </section>

    <section data-color="white">
      <footer id="colophon" class="container site-footer">
        <div class="top" />
        <hr />
        <div class="left-right">
          <div class="left text-footer-links">
            <div id="block-7" class="menu widget_block">
              <div class="menu-title">Social</div>
              <div class="wp-widget-group__inner-blocks">
                <ul>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer noopener"
                      >Instagram</a
                    >
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer noopener"
                      >Twitter</a
                    >
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer noopener"
                      >LinkedIn</a
                    >
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer noopener"
                      >Facebook</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div id="block-8" class="menu widget_block">
              <div class="menu-title">Info</div>
              <div class="wp-widget-group__inner-blocks">
                <ul>
                  <li><a href="#">About</a></li>
                  <li>
                    <a href="#" data-type="URL" data-id="/about/">Resources</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="right text-footer-links">
            <div id="block-9" class="menu widget_block">
              <div class="menu-title">Telephone Number</div>
              <div class="wp-widget-group__inner-blocks">
                <a href="tel:+1(781)-226-7009" class="text-footer-links"
                  >781-226-7009
                  </a
                >
                <br />
                <a
                  href="mailto: HarwoodHouses@gmail.com"
                  style="color: #151515"
                >
                  Email
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>

    <div id="myModal" class="modal" on:click={onCloseModal}>
      <div on:click={onCloseCarousel} id="icon" class="Icon">
        <span />
        <span />
        <span />
      </div>
      <!-- <button class="closeButton" on:click={onCloseModal}>Close Modal</button>
      <div class="carouselWrapper">
        <Carousel bind:this={carousel}>
          <img src="/images/3 Lewis St, Lynn.webp" class="modalImg" />
          <img src="/images/12 Mt. Vernon St, Salem.webp" class="modalImg" />
          <img src="/images/16 Hovey Terrace, Lynn.webp" class="modalImg" />
          <img src="/images/18 Maplewood St, Malden.webp" class="modalImg" />
          <img src="/images/19 Hanover St, Lynn.webp" class="modalImg" />
          <img src="/images/61 Breed St, Lynn.webp" class="modalImg" />
          <img src="/images/20 Harwood St, Lynn.webp" class="modalImg" />
        </Carousel>
      </div> -->

      <!-- <div class="modal-content"> -->
      <img id="myImg" />
      <!-- FLICKTY USE HERE https://flickity.metafizzy.co/-->
      <!-- </div> -->
    </div>

    <div id="modalBreed" class="modal" on:click={onCloseModal}>
      <div on:click={onCloseCarousel} id="icon" class="Icon">
        <span />
        <span />
        <span />
      </div>
      <!-- <button class="closeButton" on:click={onCloseCarousel}>Close Modal</button> -->
      <div class="carouselWrapper">
        <Carousel bind:this={carousel}>
          <img
            src="/images/BREED-2.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-3.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-4.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-1.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-5.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-6.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-7.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/BREED-8.webp"
            class="modalImg"
            alt="inside of property"
          />
        </Carousel>
      </div>

      <!-- <div class="modal-content"> -->
      <!-- <img id="myImg" /> -->
      <!-- FLICKTY USE HERE https://flickity.metafizzy.co/-->
      <!-- </div> -->
    </div>

    <div id="modalHanover" class="modal" on:click={onCloseModal}>
      <div on:click={onCloseCarousel} id="icon" class="Icon">
        <span />
        <span />
        <span />
      </div>
      <!-- <button class="closeButton" on:click={onCloseCarousel}>Close Modal</button> -->
      <div class="carouselWrapper">
        <Carousel bind:this={carousel}>
          <img
            src="/images/HANOVER-2.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-3.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-4.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-1.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-5.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-6.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-7.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-8.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-9.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-10.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-11.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-12.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-13.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/HANOVER-14.webp"
            class="modalImg"
            alt="inside of property"
          />
        </Carousel>
      </div>

      <!-- <div class="modal-content"> -->
      <!-- <img id="myImg" /> -->
      <!-- FLICKTY USE HERE https://flickity.metafizzy.co/-->
      <!-- </div> -->
    </div>

    <div id="modalLewis" class="modal" on:click={onCloseModal}>
      <div on:click={onCloseCarousel} id="icon" class="Icon">
        <span />
        <span />
        <span />
      </div>
      <!-- <button class="closeButton" on:click={onCloseCarousel}>Close Modal</button> -->
      <div class="carouselWrapper">
        <Carousel bind:this={carousel}>
          <img
            src="/images/LEWIS-7.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-8.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-9.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-10.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-11.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-12.webp"
            class="modalImg"
            alt="inside of property"
          />
          <img
            src="/images/LEWIS-13.jpg"
            class="modalImg"
            alt="inside of property"
          />
        </Carousel>
      </div>

      <!-- <div class="modal-content"> -->
      <!-- <img id="myImg" /> -->
      <!-- FLICKTY USE HERE https://flickity.metafizzy.co/-->
      <!-- </div> -->
    </div>
  </body>
</html>

<style>
  .sc-carousel-arrow__circle .svelte-zk8398 {
    background-color: #fff !important;
  }

  .Icon {
    width: 50px;
    height: 50px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin-left: 60%;
  }

  .Icon span {
    display: block;
    position: absolute;
    left: 0;
    top: 23px;
    height: 8px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
  }

  .Icon span:nth-child(1),
  .Icon span:nth-child(3) {
    width: 50%;
    transform: rotate(45deg);
  }

  .Icon span:nth-child(1) {
    left: 3px;
    top: 15px;
  }

  .Icon span:nth-child(2) {
    transform: rotate(-45deg);
  }

  .Icon span:nth-child(3) {
    left: 20px;
    top: 31px;
  }

  .modalImg {
    margin: auto;
    display: grid;
    /* height: 100vh; */
    max-height: 600px;
    place-items: center;
  }
  .carouselWrapper {
    width: 748px;
    margin-bottom: 10%;
  }
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    padding-bottom: 6rem;
    /* width: 100%;
    height: 50%; */
  }

  /* #myImg:hover {
    opacity: 0.7;
  } */

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 93; /* Sit on top */
    padding-top: 20px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: none; /* Enable scroll if needed */
    background-color: rgba(7, 7, 7, 0.891);
    place-items: center;
  }

  /* Modal Content (Image) */
  .modal-content {
    margin: auto;
    display: grid;
    /* width: 80%; */
    max-width: 1200px;
    height: 100vh;
    place-items: center;
  }

  /* Add Animation - Zoom in the Modal */
  .modal-content {
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
    #myImg {
      max-height: 448px;
      padding-bottom: 6rem;
    }
    .carouselWrapper {
      width: 394px;
    }
  }
  @media only screen and (max-width: 480px) {
    .carouselWrapper {
      width: 394px;
      max-height: 200px;
      padding-bottom: 33vh;
    }
  }
</style>
